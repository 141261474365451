@charset "UTF-8";

@import "./style.css";
// Importa o Bootstrap
@import "~bootstrap/scss/bootstrap";

$border-radius: 0.9rem; // Default rounded class
$border-radius-sm: 0.55rem; // rounded-sm class
$border-radius-lg: 1.5rem; // rounded-lg class

// Fonte do Google Fonts
body {
    color: #656161;
    font-family: 'Poppins', sans-serif;
}

// Sobrescreve a classe .bg-warning do Bootstrap
.bg-warning {
    background-color: #e9560d !important;
}

.bg-white {
    background-color: white !important;
}

.text-white {
    color: white !important;
}

.text-muted {
    color: #656161 !important;
}

.no-underline {
    text-decoration: none;
}

.border-bottom {
    border-bottom: 1px solid #e0e0e0 !important;
}

.rounded-pill {
    border-radius: 50rem !important;
}

.fw-normal {
    font-weight: normal !important;
}

.fw-bold {
    font-weight: bold !important;
}

.fw-800 {
    font-weight: 800 !important;
}

.navbar-nav {
    flex-direction: row;
}

.nav-item {
    margin-right: 15px;
}

.navbar-toggler {
    position: absolute;
    top: 0;
    right: 0;
}

ul.top-header, a.top-header {
    font-size: 12px;
}

#banner-section {
    background-image: url('../images/banner_img.png');
    background-size: auto;
    background-repeat: no-repeat;
    background-position: right;
    padding: 60px 10px 0 10px;
}

.category {
    min-height: 26px;
    padding: 0 0 0 5px;
    position: relative;
    width: 147px;
    background: #e9560d;
    color: #ffffff;
    font-size: 0.875em; /* 14px / 16px = 0.875em */
    font-weight: bold;
    line-height: 26px;
    text-align: center;
    text-transform: uppercase;
}

h1.home {
    margin: 0 0 0.50em 0; // Margem: topo direita inferior esquerda
    color: #e9560d;
    font-size: 1.5em; /* 24px / 16px = 1.5em */
    font-weight: bold;
}
h2.home {
    color: #e9560d;
    font-size: 1.125em; /* 18px / 16px = 1.125em */
    font-weight: bold;
    text-align: left;
}

p.desc-home {
    font-size: 0.875em; /* 14px / 16px = 0.875em */
    font-weight: 500;
    line-height: 1.2;
}

.card {
    position: relative; /* Para permitir posicionamento absoluto dentro do card */
    overflow: hidden; /* Garante que o conteúdo absoluto não ultrapasse os limites do card */
}

.categoria_bg-holder {
    position: absolute;
    top: 10px; /* Ajuste conforme necessário para posicionar verticalmente */
    left: 10px; /* Ajuste conforme necessário para posicionar horizontalmente */
    z-index: 10; /* Garante que o texto fique sobre a imagem */
    padding: 5px; /* Ajuste conforme necessário para o espaçamento interno */
}

// Estilo para alinhar ícone e texto verticalmente
.d-flex.align-items-center {
    img {
        vertical-align: middle; // Alinha verticalmente a imagem com o texto
    }
    p {
        margin-bottom: 0; // Remove o espaçamento inferior do parágrafo para um alinhamento mais preciso
    }
}

// Galery
.thumbnail {
    cursor: pointer;
}
.main-media {
    max-height: 500px;
    min-height: 300px; /* Altura mínima */
    min-width: 300px;  /* Largura mínima */
    object-fit: cover;
    width: 100%; /* Para ajustar a largura da imagem ao contêiner */
}
.thumbs-container {
    overflow-x: auto;
    white-space: nowrap;
}
.thumbs-container img, .thumbs-container video {
    width: 100px;
    height: auto;
    display: inline-block;
}


@media (max-width: 992px) {
    .navbar-nav {
        flex-direction: column;
    }

    .nav-item {
        margin-right: 0;
        margin-bottom: 10px;
    }

    .navbar-toggler {
        top: 10px;
        right: 10px;
    }
}

@media (max-width: 768px) {
    #banner-section {
        padding: 50px 0;
    }
}
