@charset "UTF-8";

body {
    color: #656161;
    font-family: 'Poppins', sans-serif;
}

.bg-warning {
    background-color: #e9560d !important;
}

.bg-white {
    background-color: white !important;
}

.text-white {
    color: white !important;
}

.text-muted {
    color: #656161 !important;
}

.border-bottom {
    border-bottom: 1px solid #e0e0e0 !important;
}

.rounded-pill {
    border-radius: 50rem !important;
}

.fw-normal {
    font-weight: normal !important;
}

.fw-bold {
    font-weight: bold !important;
}

.fw-800 {
    font-weight: 800 !important;
}

.navbar-nav {
    flex-direction: row;
}

.nav-item {
    margin-right: 15px;
}

.navbar-toggler {
    position: absolute;
    top: 0;
    right: 0;
}

ul.top-header, a.top-header{
    font-size:12px;
}

#banner-section {
    background-image: url(/images/banner_img.png?1a69c0ff7b932c99ada6c9b51d19583f);
    background-size: auto;
    background-repeat: no-repeat;
    background-position: right;
    padding: 60px 10px 0 10px;
}

.thumb {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin: 10px;
}

#fileInput {
    display: none;
}

@media (max-width: 992px) {
    .navbar-nav {
        flex-direction: column;
    }

    .nav-item {
        margin-right: 0;
        margin-bottom: 10px;
    }

    .navbar-toggler {
        top: 10px;
        right: 10px;
    }
}

@media (max-width: 768px) {
    #banner-section {
        padding: 50px 0;
    }
}


